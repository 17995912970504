<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.survey.pending_title") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          class="elevation-1"
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
        >
          <template #item="row">
            <tr :active="row.selected">
              <td>
                <v-list-item class="pa-0">
                  <v-list-item-avatar
                    size="30"
                    class="white--text body-1 font-weight-medium"
                    :color="row.item.publishedSurvey.survey.color"
                  >
                    {{ row.item.publishedSurvey.survey.name.substring(0, 2).toUpperCase() }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="body-1">
                      {{ row.item.publishedSurvey.survey.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td>
                {{ row.item.publishedSurvey.publishDateTime | formattedDateTimeMinute }}
              </td>
              <td>
                {{ row.item.publishedSurvey.endDateTime | formattedDateTimeMinute }}
              </td>
              <td class="text-center">
                <v-btn small color="primary" depressed @click.stop="navigateToStartSurvey(row.item.id)">
                  <v-icon left>
                    mdi-chart-box
                  </v-icon>
                  <span class="font-weight-bold">
                    {{ $t("hr.survey.start_survey") }}
                  </span>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { PENDING_SURVEY_RESULTS } from "./query";

  export default {
    name: "PendingSurveyResults",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ["publishedSurvey.publishDateTime"],
          sortDesc: [true]
        },
        totalItems: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.survey.name"),
            value: null,
            align: "start",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.survey.published_date"),
            value: "publishedSurvey.publishDateTime",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.survey.end_date"),
            value: "publishedSurvey.endDateTime",
            align: "start",
            sortable: true,
            class: ["primary--text"]
          },
          {
            align: "end",
            sortable: false,
            width: "1%"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      navigateToStartSurvey(surveyResultId) {
        let routerParams = {
          id: this.$helpers.encodeID(surveyResultId)
        };
        this.$router.push({ name: "survey_pending_start", params: routerParams });
      },
      onRefreshData() {
        this.fetchItems();
      },
      isSurveyExpired(survey) {
        const format = "YYYY-MM-DD HH:mm";
        const now = this.$moment().format(format);
        const endDate = this.$moment.utc(survey.endDateTime).format(format);
        return this.$moment(endDate).isSameOrBefore(now);
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: PENDING_SURVEY_RESULTS,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data =
                data.employee.pendingSurveyResults
                  .filter(item => !this.isSurveyExpired(item.publishedSurvey))
                  .sort((a, b) => this.$moment(b.createdDateTime).diff(this.$moment(a.createdDateTime), "seconds")) ||
                [];
              this.table.totalItems = data.employee.pendingSurveyResults.length || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>
